<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-skeleton-loader
          type="table"
          v-if="loading"
          class="mb-3"
        ></v-skeleton-loader>
        <v-row v-else>
          <v-col cols="12">
            <v-sheet :rounded="'lg'">
              <v-card-title>
                <v-text-field
                  v-model="filter.keyword"
                  rounded
                  filled
                  dense
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  clearable
                  placeholder="Rechercher..."
                />
              </v-card-title>
              <v-card-text>
                <div v-if="accounts.length > 0">
                  <v-simple-table fixed-header height="600px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th class="font-weight-bold text-no-wrap">
                            Account ID
                          </th>
                          <th class="font-weight-bold text-no-wrap">User ID</th>
                          <th class="font-weight-bold text-no-wrap">Compte</th>
                          <th class="font-weight-bold text-no-wrap text-center">
                            Balance
                          </th>
                          <th class="font-weight-bold text-no-wrap text-center">
                            Historiques
                          </th>
                          <th class="font-weight-bold text-no-wrap text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in accounts" :key="item.id">
                          <td
                            class="font-weight-bold text-no-wrap"
                            style="width: 50px"
                          >
                            <v-img :src="getImageLink(item.image)" width="50" />
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.id }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.user_id }}
                          </td>
                          <td class="font-weight-bold text-no-wrap">
                            {{ item.user ? item.user.name : "Unnamed User" }}
                          </td>
                          <td
                            class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                          >
                            {{ CurrencyFormatting(item.all_balance) }} DZD
                          </td>

                          <td class="font-weight-bold text-center text-no-wrap">
                            {{ item.balance_histories_count }}
                          </td>
                          <td class="font-weight-bold text-center text-no-wrap">
                            <v-btn
                              :to="'/balance/history/' + item.id"
                              class="rounded-lg elevation-0 secondary--text"
                              ><v-icon class="primary--text"
                                >mdi-sort-clock-descending-outline</v-icon
                              >
                              Details</v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else>
                  <div class="text-center pb-5">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      loading: true,
      accounts: [],
      allAccounts: [],

      filter: {
        keyword: "",
      },
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("anomaly/account/balance/history")
        .then((res) => {
          this.accounts = res.data.data;
          this.allAccounts = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    filterAccounts(){
        var filteredData = this.allAccounts.filter((obj) => {
            console.log(obj);
            return obj.user?  obj.user.name.toLowerCase().includes(this.keyword.toLowerCase()) : false;
        });
        this.accounts = filteredData;
    }
    
  },
  computed: {
    keyword(){
        return this.filter.keyword
    }
  },
  watch: {
    keyword() {
      this.filterAccounts();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
</style>